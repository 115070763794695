<template>
	<MhRouterView class="NotFoundView view" :isContentReady="isContentReady">

		<RbAppHeader
			:textRight="_.get( app, 'acfOptions.headerTextRight' )"
		></RbAppHeader>

		<RbAppMenu></RbAppMenu>

		<RbNotFound></RbNotFound>

	</MhRouterView>
</template>

<script>
	import { EventBus } from '@/event-bus.js'
	import RestHandler from "/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/mixins/restHandler/v1/restHandler.mixin.js"
	import MhRouterView from '/Users/Mario/Dropbox/htdocs/2019-05-20__wp-kickstart-vue/wordpress/wp-content/themes/wp-kickstart-v3-theme/vue-cli-dev/src/components/MhRouterView/v4/MhRouterView.vue'

	import RbAppHeader from "@/components/RbAppHeader.vue"
	import RbAppMenu from "@/components/RbAppMenu.vue"
	import RbNotFound from '@/components/RbNotFound.vue'
	import RbAppFooter from "@/components/RbAppFooter.vue"

	export default {
		name: 'NotFoundView',
		components: {
			MhRouterView,
			RbAppHeader,
			RbAppMenu,
			RbNotFound,
			RbAppFooter,
		},
		props: {},
		data() {
			return {
				isContentReady: false,
			}
		},
		computed: {
			app(){
				return this.$root.$children[0]
			},
			currentUrl(){
				return window.location
			}
		},
		methods: {},
		mounted(){
			this.isContentReady = true
		}
	}
</script>

<style lang="less">
	@import (reference) "@/less/vars.less";
	@import (reference) "@/less/mixins.less";
	@import (reference) "@/less/atoms.less";

	.NotFoundView {}

	@media @mq[xs] {}
	@media @mq[sm] {}
	@media @mq[md] {}
	@media @mq[dt] {}
	@media @mq[lg] {}
	@media @mq[xl] {}
</style>
